import { Modal, Spin, message, Upload } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import MapIcon from "@/assets/images/map-grey-icon.svg";
import UploadIcon from "@/assets/images/upload.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  closeBuildingResourceModal,
  getBuildingData,
  getBuildingDataCount,
  setBuildingId,
} from "../../redux/slice/buildingSlice";
import { Link, useLocation } from "react-router-dom/dist";
import { getFirstPathSegment } from "../../services/Utils";
import CheckIcon from "../icons/checkIcon";
import CrossIcon from "../icons/crossIcon";
import { getSampleDes } from "../../redux/slice/sampleSlice";
import API from "../../services/ApiService";
import { openNotificationWithIcon } from "../../services/toast";
import { ApiEndPoint } from "../../services/ApiEndpoint";

export default function BuildingModal({ buildingId }) {
  const location = useLocation();
  const pathName = getFirstPathSegment(location?.pathname);
  const dispatch = useDispatch();
  const {
    buildingResourcesModal,
    buildingDetails,
    defaultBuildingId,
    buildingDataCount,
    isLoading,
  } = useSelector((state) => state.building);
  const { isLoading: loading, noticeData } = useSelector(
    (state) => state.sample
  );
  useEffect(() => {
    if (buildingId) {
      dispatch(setBuildingId(buildingId));
      dispatch(getBuildingData(buildingId));
      dispatch(getSampleDes(buildingId));
    }
  }, [buildingId]);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailBlur = () => {
    if (!email || !validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleFileChange = (info) => {
    const { status, originFileObj } = info.file;
    if (status === "uploading") {
      setSelectedFile(originFileObj);
    }
    if (status === "done") {
      openNotificationWithIcon(
        "success",
        "File selected. Please click on Upload"
      );
    } else if (status === "error") {
      openNotificationWithIcon("error", "File upload failed.");
    }
  };

  const handleOk = async () => {
    if (!email) {
      openNotificationWithIcon(
        "error",
        "Please add your email before uploading"
      );
      return;
    }
    if (!validateEmail(email)) {
      openNotificationWithIcon("error", "Please enter a valid email address");
      return;
    }
    if (!selectedFile) {
      openNotificationWithIcon("error", "Please select a file to upload");
      return;
    }
    setConfirmLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("email", email);
      formData.append("BUILDING_ID", buildingDetails?.BUILDING_ID);

      const response = await API.post(ApiEndPoint.resultCsv, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      openNotificationWithIcon(
        "success",
        "File uploaded successfully. Processing will continue in the background."
      );
    } catch (error) {
      openNotificationWithIcon("error", "File upload failed.");
      console.error("File upload error:", error);
    } finally {
      setConfirmLoading(false);
      setModalOpen(false);
      setEmail("");
      setEmailError("");
      const deleteButton = document.querySelector(
        ".ant-upload-list-item-action"
      );
      if (deleteButton) {
        deleteButton.click();
      } else {
        console.log("No file present");
      }
    }
  };

  const [email, setEmail] = useState("");
  const { Dragger } = Upload;

  useEffect(() => {
    if (buildingId && buildingId != null) {
      dispatch(
        getBuildingDataCount(
          pathName,
          buildingId ?? defaultBuildingId,
          pathName
        )
      );
    }
  }, [buildingId]);

  return (
    <>
      {isLoading && buildingResourcesModal ? (
        <Spin delay={20} fullscreen size="large" />
      ) : (
        ""
      )}
      {buildingResourcesModal ? (
        <Modal
          width={1340}
          className="update-building-modal"
          centered
          open={buildingResourcesModal}
          onCancel={() => {
            dispatch(closeBuildingResourceModal());
          }}
          footer={false}
        >
          <div className="upper-header">
            {/* ID */}
            <div className="title">{`${buildingDetails?.BUILDING_ID || "N/A"} Building Details`}</div>

            <Link
              to={`/${pathName}/buildings/update/${buildingDetails?.BUILDING_ID}`}
              className="link"
            >
              Update Building
            </Link>
          </div>
          <div className="header-content">
            <div className="lower-header flex-grow-1">
              <h3 className="title">
                {buildingDetails?.BUILDING_NAME || "N/A"}
              </h3>
              <div className="details">
                <div className="top">
                  <div className="details-item">
                    <span>
                      {buildingDetails?.ADDRESS
                        ? buildingDetails.ADDRESS
                        : "N/A"}
                      {buildingDetails?.ADDRESS && buildingDetails?.CITY
                        ? ", "
                        : ""}
                      {buildingDetails?.CITY || ""}
                    </span>
                  </div>
                </div>
                <div className="bottom d-flex">
                  <div className="details-item w-50">
                    <img src={MapIcon} alt="" />
                    <span>
                      {buildingDetails?.STATE ? buildingDetails.STATE : "N/A"}
                      {buildingDetails?.STATE && buildingDetails?.ZIP
                        ? " "
                        : ""}
                      {buildingDetails?.ZIP ? buildingDetails.ZIP : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="details-list">
              <p>
                {buildingDetails?.has_full_survey ? (
                  <CheckIcon />
                ) : (
                  <CrossIcon />
                )}{" "}
                Full building survey
              </p>
              <p>
                {" "}
                {buildingDetails?.DEMOLISHED ? <CheckIcon /> : <CrossIcon />}
                Demolished
              </p>
              <p>
                {" "}
                {buildingDetails?.CERTIFIED ? <CheckIcon /> : <CrossIcon />}
                Certified
              </p>
            </div>
          </div>
          <div className="body-content">
            <div className="button-rows">
              <label htmlFor="" className="label">
                Results
              </label>
              <div className="buttons">
                <a className="px-5" onClick={() => setModalOpen(true)}>
                  Upload CSV
                </a>
                <a
                  className="px-5"
                  href={`/lead/edit-entries/${defaultBuildingId}`}
                >
                  Edit Entries
                </a>
              </div>
            </div>
            <div className="button-rows my-4">
              <label htmlFor="" className="label">
                Tables & Reports
              </label>
              <div className="buttons">
                {" "}
                {[
                  {
                    name: "View Table 1",
                    src: `/${pathName}/table-one/${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                    },
                  },
                  {
                    name: "View Table 2",
                    src: `/${pathName}/table-two/${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                    },
                  },
                  {
                    name: "View Table 3",
                    src: `/${pathName}/table-three/${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                    },
                  },
                ].map((i, index) =>
                  i.component ? (
                    <div key={index} className="link">
                      {i.component}
                    </div>
                  ) : (
                    <Link key={index} to={i.src} state={i.state}>
                      {i.name}
                    </Link>
                  )
                )}
              </div>
            </div>
            <div className="button-rows">
              <label htmlFor="" className="label">
                Attachments
              </label>
              <div className="buttons">
                {[
                  {
                    name: `Lead Attachment · ${buildingDataCount.leadAtcCount || 0}`,
                    src: `/${pathName}/lead-attachments?id=${defaultBuildingId}`,
                    state: {
                      buildingId,
                      building: buildingDetails?.BUILDING_ID,
                      message:
                        "By selecting Yes, you are certifying that you reviewed the data and validate that Lead was detected in the reports",
                      item: "lead",
                    },
                  },
                ].map((i, index) => (
                  <Link key={index} to={i.src} state={i.state}>
                    {i.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <Modal
            width={480}
            title="Upload Results"
            centered
            open={modalOpen}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={() => {
              setModalOpen(false);
              setEmail("");
              setEmailError("");
              const deleteButton = document.querySelector(
                ".ant-upload-list-item-action"
              );
              if (deleteButton) {
                deleteButton.click();
              } else {
                console.log("No file present");
              }
            }}
            okText="Upload"
            footer={(_, { OkBtn }) => (
              <>
                <OkBtn />
              </>
            )}
          >
            <input
              type="email"
              id="email-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={handleEmailBlur}
              placeholder="Enter your email"
              className={`email-input mb-1 ${emailError ? "input-error" : ""}`}
              style={{
                padding: "8px",
                border: emailError ? "1px solid red" : "1px solid #ccc",
                borderRadius: "4px",
                width: "100%",
              }}
            />
            {emailError && (
              <span
                className="error-message"
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginTop: "4px",
                }}
              >
                {emailError}
              </span>
            )}
            <Dragger
              accept=".csv"
              multiple={false}
              customRequest={dummyRequest}
              onChange={(info) => {
                handleFileChange(info);
              }}
              onRemove={() => {
                setSelectedFile(null);
              }}
              style={{ marginTop: "16px" }}
            >
              <img src={UploadIcon} alt="Upload" />
              <p className="ant-upload-text m-0" style={{ color: "#3582D7" }}>
                Choose File
              </p>
              <p className="ant-upload-hint m-0" style={{ color: "#A2A2A2" }}>
                You can upload file in .csv format
              </p>
            </Dragger>
          </Modal>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}
