export const ApiEndPoint = {
  //AUTH
  signup: "auth/signUp",
  login: "auth/login",
  changePassword: "auth/changePassword",
  forgotPassword: "auth/forgotPassword",
  verifyUser: "auth/verifyUser",
  users: "auth/users",
  user: "auth/user",
  updateProfile: "auth/userProfile",

  //DASHBOARD
  dashboadCount: "dashboard/count",
  dashboadGraphData: "dashboard/graphData",
  management_plan: "v1/management_plan",

  //HOME
  loginDetails: "home/loginDetails",

  //BUILDING
  building: "v1/building",
  buildingsRecord: "/v1/buildings/record",
  buildingsAsbRecord: "/v1/buildingsAsb/record",
  buildingsLeadRecord: "/v1/buildingsLead/record",
  buildingsAsb: "v1/buildings/asb",
  buildingsLead: "v1/buildings/lead",
  archiveBuilding: "v1/building/archive",
  unarchiveBuilding: "v1/building/unarchive",
  //SURVEY
  allSurvey: "/v1/building/survey",
  survey: "v1/survey",

  //SAMPLE
  allSample: "/v1/building/sample",
  samplesRecord: "/v1/building/sample/record",
  sample: "v1/sample",
  sampleAreaByBuilding: "v1/building/sample/area/",
  sampleDesByBuilding: "v1/building/sample/des/",
  uploadSamplePicture: "v1/samplePictures/",

  // TABLE
  table: "v1/building/table",
  // table2:"v1/building/table2",

  //RESULTS
  allResults: "/v1/building/results",
  result: "/v1/result",
  resultCsv: "/v1/result/lead/csv",
  resultsBySample: "/v1/sample/results",
  editEntriesTable: "/v1/result/lead/",
  tableOne: "/v1/leadTable1/results/",
  tableTwo: "/v1/leadTable2/results/",
  editEntries: "/v1/lead/result/",
  deleteEntries: "/v1/result/lead/",
  resultsRecord: "/v1/building/results/record",
  resultsHistory: "/v1/building/results/history",
  //ASSESSMENTS
  allAssessments: "v1/building/assessments",
  assessment: "v1/assessment",
  assessmentsRecord: "/v1/building/assessments/record",
  addRemoveAssessmentOnm: "/v1/assessment/onm/",
  getAssessmentOnm: "/v1/assessment/onm/",


  //BuildingDetails
  modalCount: "v1/building/dataCount",

  //Attachment
  attachments: 'v1/attachments/asbestos',
  attachment: 'v1/attachment/asbestos',

  //COMMON
  commonGet: "common",
  common: "common",
  laboratory: "common/getLaboratory",
  materialType: "common/getMaterial",
  inspector: "common/getInspectors",
  materialCondition: "common/getMaterialCondition",
  equipmentType: "common/getEquipmentType",
  constructionType: "common/getConstructionType",
  accessDifficulty: "common/getAccessDifficulty",
  hazardAssesment: "common/getHazardAssesment",
  assessmentQuesAns: "common/getAssessmentQuestionsWithOptions",
  result_type: "common/getResultType",
  colorTexture: "common/getColorTexture",
  uncommonColorTexture: "common/getUncommonColorTexture",
  sampleCondition: "common/getSampleCondition"
};
