import { createSlice } from "@reduxjs/toolkit";
import API from "../../services/ApiService";
import { openNotificationWithIcon } from "../../services/toast";
import { ApiEndPoint } from "../../services/ApiEndpoint";

const initialState = {
  rowData: [],
  currentPage: 1,
  totalEntries: 0,
  pageSize: 10,
  isLoading: false,
  error: null,
};

const entriesSlice = createSlice({
  name: "entries",
  initialState,
  reducers: {
    setRowData(state, action) {
      // state.rowData = action.payload;
      state.rowData = [...action.payload];
    },
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    changePage(state, action) {
      state.currentPage = action.payload.page;
      state.pageSize = action.payload.pageSize;
    },
    setTotalEntries(state, action) {
      state.totalEntries = action.payload;
    },
  },
});

export const {
  setRowData,
  startLoading,
  stopLoading,
  setError,
  changePage,
  setTotalEntries,
} = entriesSlice.actions;

export const fetchEntries =
  (buildingId, page, pageSize) => async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await API.get(
        `${ApiEndPoint.editEntriesTable}${buildingId}?skip=${(page - 1) * pageSize}&limit=${pageSize}`
      );
      dispatch(setRowData(response?.data?.data?.listing || []));
      dispatch(setTotalEntries(response?.data?.data?.count || 0));
    } catch (error) {
      dispatch(setError("Error fetching data"));
      openNotificationWithIcon("error", "Error in fetching data");
    } finally {
      dispatch(stopLoading());
    }
  };

export const fetchTableOne =
  (buildingId, page, pageSize) => async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await API.get(
        `${ApiEndPoint.tableOne}${buildingId}?skip=${(page - 1) * pageSize}&limit=${pageSize}`
      );
      dispatch(setRowData(response?.data?.data?.listing || []));
      dispatch(setTotalEntries(response?.data?.data?.count || 0));
    } catch (error) {
      dispatch(setError("Error fetching data"));
      openNotificationWithIcon("error", "Error in fetching data");
    } finally {
      dispatch(stopLoading());
    }
  };

export const fetchTableTwo =
  (buildingId, page, pageSize) => async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await API.get(
        `${ApiEndPoint.tableTwo}${buildingId}?skip=${(page - 1) * pageSize}&limit=${pageSize}`
      );
      dispatch(setRowData(response?.data?.data?.listing || []));
      dispatch(setTotalEntries(response?.data?.data?.count || 0));
    } catch (error) {
      dispatch(setError("Error fetching data"));
      openNotificationWithIcon("error", "Error in fetching data");
    } finally {
      dispatch(stopLoading());
    }
  };

  export const updateEntry = (entryDetails, id, buildingId, currentPage, pageSize) => {
    return async function (dispatch, getState) {
      try {
        await API.put(ApiEndPoint.editEntries + id, entryDetails);
        dispatch(fetchEntries(buildingId, currentPage, pageSize));
        openNotificationWithIcon("success", "Entry updated successfully!");
      } catch (error) {
        openNotificationWithIcon("error", error?.response?.data?.message);
      }
    };
  }

export const deleteEntry = (id, rowIndex) => async (dispatch, getState) => {
  try {
    await API.delete(ApiEndPoint.deleteEntries + id);
    const updatedData = getState().entries.rowData.filter(
      (_, index) => index !== rowIndex
    );
    dispatch(setRowData(updatedData));
    openNotificationWithIcon("success", "Entry deleted successfully!");
  } catch (error) {
    dispatch(setError("Error deleting entry"));
    openNotificationWithIcon("error", "Failed to delete entry");
  }
};

export default entriesSlice.reducer;
