import React, { useEffect, useState } from "react";
import { Button, Modal, Pagination, Spin } from "antd";
import { GoBack, HeaderText, MainLayout } from "@/components";
import "../../asbestos/tables/tables.scss";
import TrashIcon from "@/assets/images/trash-red-icon.svg";
import EditIcon from "@/assets/images/edit-blue-icon.svg";
import InputText from "./../../../components/form-group/inputText";
import { openNotificationWithIcon } from "../../../services/toast";
import { useLocation } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import confirm from "antd/es/modal/confirm";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEntries,
  updateEntry,
  deleteEntry,
  changePage,
} from "../../../redux/slice/entriesSlice";

function EditEntries() {
  const location = useLocation();
  const buildingId = location.pathname.split("/").pop();
  const dispatch = useDispatch();
  const { rowData, isLoading, totalEntries, pageSize, currentPage } =
    useSelector((state) => state.entries);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // const [editRowIndex, setEditRowIndex] = useState(null);
  const [editRowData, setEditRowData] = useState({});
  const [changedData, setChangedData] = useState({});

  useEffect(() => {
    if (buildingId) {
      dispatch(fetchEntries(buildingId, 1, pageSize));
    }
  }, [buildingId, dispatch, pageSize]);

  const handlePageChange = (page, pageSize) => {
    dispatch(changePage({ page, pageSize }));
    dispatch(fetchEntries(buildingId, page, pageSize));
  };

  const handleEditClick = (index) => {
    // setEditRowIndex(index);
    setEditRowData({ ...rowData[index] });
    setIsEditModalOpen(true);
  };

  function handleChangeUpdate(e) {
    const { name, value } = e.target;
    const data = { [name]: value };
    setEditRowData((prev) => ({
      ...prev,
      ...data,
    }));

    setChangedData((prev) => ({
      ...prev,
      ...data,
    }));
  }

  function handleUpdate() {
    if (!Object.keys(changedData)?.length) {
      return openNotificationWithIcon(
        "error",
        "Please add some data to update"
      );
    }
    dispatch(updateEntry(changedData, editRowData?.id, buildingId, currentPage, pageSize));
    setIsEditModalOpen(false);
    setChangedData({});
  }

  const formatValue = (value) =>
    value !== "N/A" && (value || value === 0) ? value : "";

  const handleDeleteRow = (index) => {
    const rowId = rowData[index]?.id;
    if (!rowId) return;

    confirm({
      title: "Are you sure you want to delete this entry?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      content: (
        <div style={{ position: "relative", top: "44px", left: "200px" }}>
          <Button
            onClick={() => {
              Modal.destroyAll();
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      ),
      onOk() {
        try {
          dispatch(deleteEntry(rowId, index));
        } catch (error) {
          openNotificationWithIcon("error", "Failed to delete entry");
        }
      },
      onCancel() {
        console.log("Action cancelled");
      },
    });
  };

  return (
    <MainLayout>
      <div className="table-one-wrapper tables-page-wrapper">
        <HeaderText>
          <GoBack />
          Edit Entries
        </HeaderText>
        {isLoading ? (
          <Spin delay={20} fullscreen size="large" />
        ) : (
          <div className="table-wrapper">
            <div className="table-outer-header mb-1">
              {/* <SearchInput
                width={300}
                placeholder="Search here..."
                className="user-search-input"
              /> */}
            </div>
            <div
              className="table-overflow-wrapper container p-0"
              id="customScrollTable"
            >
              <table>
                <thead>
                  <tr>
                    <th>Test</th>
                    <th>Serial</th>
                    <th>Pass/Fail</th>
                    <th>Unit Type</th>
                    <th>Calibration Model / Matrix ID</th>
                    <th>Display Sigma</th>
                    <th>LOD Sigma</th>
                    <th>Pb</th>
                    <th>Pb +/-</th>
                    <th>Pb P/F</th>
                    <th>Result</th>
                    <th>Lead (mg/cm²)</th>
                    <th>Mode</th>
                    <th>Job #</th>
                    <th>Wall</th>
                    <th>Structure</th>
                    <th>Location</th>
                    <th>Member</th>
                    <th>Paint Condition</th>
                    <th>Substrate</th>
                    <th>Color</th>
                    <th>Room Name</th>
                    <th>Building Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {rowData.map((data, index) => (
                    <tr key={index}>
                      <td>{formatValue(data.TestNumber)}</td>
                      <td>{formatValue(data.SerialNumber)}</td>
                      <td>{formatValue(data.PassFail)}</td>
                      <td>{formatValue(data.UnitType)}</td>
                      <td>{formatValue(data.CalibrationModelMatrixID)}</td>
                      <td>{formatValue(data.DisplaySigma)}</td>
                      <td>{formatValue(data.LODSigma)}</td>
                      <td>{formatValue(data.Pb)}</td>
                      <td>{formatValue(data.PbPlusMinus)}</td>
                      <td>{formatValue(data.PbPassFail)}</td>
                      <td>{formatValue(data.Result)}</td>
                      <td>{formatValue(data.Lead_mg_cm2)}</td>
                      <td>{formatValue(data.Mode)}</td>
                      <td>{formatValue(data.Job_Number)}</td>
                      <td>{formatValue(data.Wall)}</td>
                      <td>{formatValue(data.Structure)}</td>
                      <td>{formatValue(data.Location)}</td>
                      <td>{formatValue(data.Member)}</td>
                      <td>{formatValue(data.Paint_Condition)}</td>
                      <td>{formatValue(data.Substrate)}</td>
                      <td>{formatValue(data.Color)}</td>
                      <td>{formatValue(data.Room_Name)}</td>
                      <td>{formatValue(data.Building_Type)}</td>
                      <td>
                        <div className="action-btn gap-4">
                          <img
                            className="edit-icon"
                            src={EditIcon}
                            alt="Edit"
                            onClick={() => handleEditClick(index)}
                            style={{ cursor: "pointer" }}
                          />
                          <img
                            className="trash-icon"
                            src={TrashIcon}
                            alt="Delete"
                            onClick={() => handleDeleteRow(index)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      {isEditModalOpen && (
        <Modal
          title="Edit Entry"
          centered
          open={isEditModalOpen}
          onCancel={() => setIsEditModalOpen(false)}
          onOk={handleUpdate}
          okText="Edit"
          width={1000}
          footer={(_, { OkBtn }) => (
            <>
              <OkBtn />
            </>
          )}
        >
          <div className="edit-form">
            <div className="row">
              <InputText
                name="TestNumber"
                label="Test Number"
                className="mb-3 w-25"
                type="number"
                value={formatValue(editRowData?.TestNumber)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="SerialNumber"
                label="Serial Number"
                className="mb-3 w-25"
                value={formatValue(editRowData?.SerialNumber)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="PassFail"
                label="Pass/Fail"
                className="mb-3 w-25"
                value={formatValue(editRowData?.PassFail)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="UnitType"
                label="Unit Type"
                className="mb-3 w-25"
                value={formatValue(editRowData?.UnitType)}
                onChange={handleChangeUpdate}
              />
            </div>

            <div className="row">
              <InputText
                name="CalibrationModelMatrixID"
                label="Calibration Model / Matrix ID"
                className="mb-3 w-25"
                value={formatValue(editRowData?.CalibrationModelMatrixID)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="DisplaySigma"
                label="Display Sigma"
                className="mb-3 w-25"
                type="number"
                value={formatValue(editRowData?.DisplaySigma)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="LODSigma"
                label="LOD Sigma"
                className="mb-3 w-25"
                type="number"
                value={formatValue(editRowData?.LODSigma)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="Pb"
                label="Pb"
                className="mb-3 w-25"
                type="number"
                value={formatValue(editRowData?.Pb)}
                onChange={handleChangeUpdate}
              />
            </div>

            <div className="row">
              <InputText
                name="PbPlusMinus"
                label="Pb +/-"
                className="mb-3 w-25"
                type="number"
                value={formatValue(editRowData?.PbPlusMinus)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="PbPassFail"
                label="Pb P/F"
                className="mb-3 w-25"
                value={formatValue(editRowData?.PbPassFail)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="Result"
                label="Result"
                className="mb-3 w-25"
                value={formatValue(editRowData?.Result)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="Lead_mg_cm2"
                label="Lead"
                className="mb-3 w-25"
                type="number"
                value={formatValue(editRowData?.Lead_mg_cm2)}
                onChange={handleChangeUpdate}
              />
            </div>

            <div className="row">
              <InputText
                name="Mode"
                label="Mode"
                className="mb-3 w-25"
                value={formatValue(editRowData?.Mode)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="Job_Number"
                label="Job Number"
                className="mb-3 w-25"
                type="number"
                value={formatValue(editRowData?.Job_Number)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="Wall"
                label="Wall"
                className="mb-3 w-25"
                value={formatValue(editRowData?.Wall)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="Structure"
                label="Structure"
                className="mb-3 w-25"
                value={formatValue(editRowData?.Structure)}
                onChange={handleChangeUpdate}
              />
            </div>

            <div className="row">
              <InputText
                name="Location"
                label="Location"
                className="mb-3 w-25"
                value={formatValue(editRowData?.Location)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="Member"
                label="Member"
                className="mb-3 w-25"
                value={formatValue(editRowData?.Member)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="Paint_Condition"
                label="Paint Condition"
                className="mb-3 w-25"
                value={formatValue(editRowData?.Paint_Condition)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="Substrate"
                label="Substrate"
                className="mb-3 w-25"
                value={formatValue(editRowData?.Substrate)}
                onChange={handleChangeUpdate}
              />
            </div>

            <div className="row">
              <InputText
                name="Color"
                label="Color"
                className="mb-3 w-25"
                value={formatValue(editRowData?.Color)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="Room_Name"
                label="Room Name"
                className="mb-3 w-25"
                value={formatValue(editRowData?.Room_Name)}
                onChange={handleChangeUpdate}
              />
              <InputText
                name="Building_Type"
                label="Building Type"
                className="w-25"
                value={formatValue(editRowData?.Building_Type)}
                onChange={handleChangeUpdate}
              />
            </div>
          </div>
        </Modal>
      )}
      {!isLoading && totalEntries > 0 && totalEntries > 10 && (
        <div className="pagination">
          <Pagination
            current={currentPage}
            total={totalEntries}
            pageSize={pageSize}
            onChange={handlePageChange}
          />
        </div>
      )}
    </MainLayout>
  );
}

export default EditEntries;
