import React, { useEffect, useState } from "react";
import { Pagination, Spin } from "antd";
import { fetchEntries, changePage } from "../../../redux/slice/entriesSlice";
import { GoBack, HeaderText, MainLayout } from "@/components";
import "./tables.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function LeadTableOne() {
  const { rowData, isLoading, pageSize, currentPage } = useSelector(
    (state) => state.entries
  );
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const buildingId = pathSegments[pathSegments.length - 1];
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (buildingId) {
      dispatch(fetchEntries(buildingId, 1, pageSize));
    }
  }, [buildingId, dispatch, pageSize]);

  useEffect(() => {
    const filtered = rowData.filter((data) => {
      const locationFields = [
        data.Room_Name,
        data.Wall,
        data.Structure,
        data.Location,
      ]
        .filter((item) => item !== "N/A" && item)
        .join(" ");

      const descriptionFields = [
        data.Paint_Condition,
        data.Substrate,
        data.Color,
      ]
        .filter((item) => item !== "N/A" && item)
        .join(" ");

      return locationFields || descriptionFields;
    });

    setFilteredData(filtered);
  }, [rowData]);

  const handlePageChange = (page, pageSize) => {
    dispatch(changePage({ page, pageSize }));
    dispatch(fetchEntries(buildingId, page, pageSize));
  };

  return (
    <MainLayout>
      <div className="table-two-wrapper">
        <HeaderText>
          <GoBack />
          Table 1 · <span className="header-light-text">{buildingId}</span>
        </HeaderText>
        <div className="table-wrapper">
          {isLoading ? (
            <Spin delay={20} fullscreen size="large" />
          ) : (
            <div className="table-wrapper">
              <div
                className="table-overflow-wrapper container p-0"
                id="customScrollTable"
              >
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "50%" }}>
                        Location of Homogenous Areas
                      </th>
                      <th style={{ width: "50%" }}>
                        Description of Homogenous Areas
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((data, index) => {
                      const locationFields = [
                        data.Room_Name,
                        data.Wall,
                        data.Structure,
                        data.Location,
                      ]
                        .filter((item) => item !== "N/A" && item)
                        .join(" ");

                      const descriptionFields = [
                        data.Paint_Condition,
                        data.Substrate,
                        data.Color,
                      ]
                        .filter((item) => item !== "N/A" && item)
                        .join(" ");

                      return (
                        <tr key={index}>
                          <td style={{ width: "50%" }}>{locationFields}</td>
                          <td style={{ width: "50%" }}>{descriptionFields}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        {!isLoading && filteredData.length > 10 && (
          <div className="pagination">
            <Pagination
              current={currentPage}
              total={filteredData.length}
              pageSize={pageSize}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
}

export default LeadTableOne;
