import React, { Fragment, useEffect, useState } from "react";
import {
  ExcelDownloadBtn,
  GoBack,
  HeaderText,
  MainLayout,
  PdfDownloadBtn,
  SearchInput,
} from "@/components";
import BlueCheckIcon from "@/assets/images/blue-check-icon.svg";
import RedCrossIcon from "@/assets/images/red-x-icon.svg";
import { Empty, Pagination, Select, Spin } from "antd";
import "./tables.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultTable,
  getLabReports,
  getTable,
  changePage,
  getTableRecord,
  getLabReportsRecord,
  stopLoading,
  startLoading,
} from "../../../redux/slice/sampleSlice";
import { useLocation, useParams } from "react-router-dom";
import { getCommon } from "../../../redux/slice/sampleSlice";
import {
  handleExcelDownload,
  handlePdfDownload,
} from "../../../services/exportHandlers";
import { downloadFile, getFirstPathSegment } from "../../../services/Utils";
import { ApiEndPoint } from "../../../services/ApiEndpoint";
import { config } from "../../../config/config";

const MATERIAL_TYPE = {
  A: "Asbestos",
  ND: "Non-Detect",
  LD: "Lead",
  NF: "Non-Fibrous",
  OF: "Other-Fibrous",
};

function AsbestosTableThree() {
  const location = useLocation();
  const params = useParams();
  const building = location?.state?.building;
  const pathName = getFirstPathSegment(location?.pathname);
  const url = location.pathname;
  const [currentTable, setCurrentTable] = useState(null);
  const [layerTypeOptions, setLayerTypeOptions] = useState({});
  const [search, setSearch] = useState("");
  const {
    tableData,
    isLoading,
    totalTableData,
    currentPage,
    matl_condition,
    material_type,
    exposed_visible,
  } = useSelector((state) => state.sample);

  let dispatch = useDispatch();
  useEffect(() => {
    const parts = url.split("/");
    const table = parts[parts.length - 2];
    console.log(tableData);

    if (table === "table-one") {
      setCurrentTable(1);
      dispatch(getTable(1, params?.id, pathName, search));
    } else if (table === "table-three") {
      setCurrentTable(3);
      dispatch(getTable(3, params?.id, pathName, search));
    } else if (table === "table-two") {
      setCurrentTable(2);
      dispatch(getTable(2, params?.id, pathName, search));
    } else if (table === "lab-report") {
      setCurrentTable(4);
      dispatch(getLabReports(params?.id, null, pathName, search));
    }
  }, [currentPage]);

  async function getOptions() {
    let asbestos_type = await dispatch(getCommon("asbestos_type"));
    let other_fibrous = await dispatch(getCommon("other_fibrous"));
    let non_fibrous = await dispatch(getCommon("non_fibrous"));
    setLayerTypeOptions(() => ({
      A: asbestos_type,
      OF: other_fibrous,
      NF: non_fibrous,
      LD: asbestos_type,
    }));
  }
  useEffect(() => {
    if (currentTable == 1) {
      // if (tableData?.CATEGORY === 'A') {
      // }
      dispatch(getCommon("material_type"));
      dispatch(getCommon("matl_condition"));
      dispatch(getCommon("exposed_visible"));
      getOptions();
    }
  }, [currentTable]);

  async function handleFile(file) {
    try {
      let url;
      let type = file === "xlsx" ? "excel" : "pdf";
      dispatch(startLoading());

      if (currentTable == 1 || currentTable == 2 || currentTable == 3) {
        url = `${ApiEndPoint.table}${currentTable}/${params.id}?format=${type}`;
      } else if (currentTable == 4) {
        url = `v1/building/results/${params.id}/record?format=${type}&category=${pathName}`;
      }
      const fileName = `table-${currentTable !== 4 ? currentTable : "Lab-reports"}`;
      await downloadFile(file, url, fileName);
    } catch (error) {
      console.log(error, "error");
    } finally {
      dispatch(stopLoading());
    }
  }
  const handleSearch = (e) => {
    setSearch(e.target.value);
    dispatch(getTable(currentTable, params?.id, pathName, e.target.value));
  };

  useEffect(() => {
    return () => {
      dispatch(changePage(1));
      dispatch(defaultTable());
    };
  }, []);

  const handlePageChange = (page) => {
    dispatch(changePage(page));
  };

  return (
    <MainLayout>
      <div className="table-two-wrapper">
        <HeaderText>
          <GoBack />
          {currentTable === 4
            ? `Lab Report(${totalTableData}) .`
            : `Table ${currentTable} (${totalTableData ?? ""})·`}

          <span className="header-light-text">FJLC {building}</span>
          <div className="d-flex justify-content-end">
            <div className="d-flex gap-2">
              {currentTable && (
                <>
                  <ExcelDownloadBtn onClick={() => handleFile("xlsx")}/>
                  <PdfDownloadBtn onClick={() => handleFile("pdf")} />
                </>
              )}
            </div>
          </div>
        </HeaderText>
        <div className="table-wrapper">
          {isLoading ? (
            <Spin fullscreen size="large" />
          ) : (
            <div className="table-overflow-wrapper" id="customScrollTable">
              {currentTable === 3 ? (
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "100px" }}>ASB_ID</th>
                      <th style={{ width: "150px" }}>SAMPLE NUMBER</th>
                      <th style={{ width: "100px" }}>MATERIAL DESCRIPTION</th>
                      <th style={{ width: "70px" }}>Layer</th>
                      <th style={{ width: "200px" }}>Location of Sample</th>
                      <th style={{ width: "100px" }}>Drawing NUMBER</th>
                      <th style={{ width: "100px" }}>Percent</th>
                      <th style={{ width: "100px" }}>Material</th>
                      <th style={{ width: "100px" }}>Found In</th>
                      <th style={{ width: "150px" }}>Reason Not Sampled</th>
                      <th style={{ width: "100px" }}>Removed?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.groupedResult?.ASBGroupedData?.map(
                      (asbItem) => (
                        <React.Fragment key={asbItem.ASB_ID}>
                          {asbItem?.Samples?.map((sample, sampleIndex) => (
                            <React.Fragment key={sample.SAMP_ASB_ID}>
                              {sample?.Results?.map((result, resultIndex) => (
                                <tr
                                  key={`${sample.SAMP_ASB_ID}-${resultIndex}`}
                                >
                                  <td style={{ width: "100px" }}>
                                    {sampleIndex === 0 && resultIndex === 0
                                      ? asbItem.ASB_ID
                                      : ""}
                                  </td>

                                  <td style={{ width: "150px" }}>
                                    {resultIndex === 0 ? sample.SAMPLE_ID : ""}
                                  </td>

                                  <td style={{ width: "100px" }}>
                                    {sample.DESCR}
                                  </td>

                                  <td style={{ width: "70px" }}>
                                    {result.LAYER_ID ?? "--"}
                                  </td>

                                  <td style={{ width: "200px" }}>
                                    {asbItem.COMMENTS}
                                  </td>

                                  <td style={{ width: "100px" }}>--</td>

                                  <td style={{ width: "100px" }}>
                                    {result.PERCENT1 ?? "--"}
                                  </td>

                                  <td style={{ width: "100px" }}>
                                    {result.MATERIAL ?? "--"}
                                  </td>

                                  <td style={{ width: "100px" }}>
                                    {result.ASB_MATERIAL ?? "--"}
                                  </td>

                                  <td style={{ width: "150px" }}>
                                    {sample.REASON_NO_SMPLE_CD ?? "--"}
                                  </td>

                                  <td style={{ width: "100px" }}>
                                    {asbItem.REMOVED ? "Yes" : "No"}
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      )
                    )}
                  </tbody>
                </table>
              ) : currentTable == 1 ? (
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div className="d-flex align-items-center gap-1">
                          HGA <i class="fi fi-sr-sort"></i>
                        </div>
                      </th>
                      <th>MATERIAL CODE</th>
                      <th>dESCRIPTION/ LOCATION</th>
                      <th>QUANTITY (LF OR SQFT)</th>
                      <th># OF eib & vivs</th>
                      <th>Photo Frame Number</th>
                      <th>#Results</th>
                      <th>asbestos</th>
                      <th>friable</th>
                      <th>Material Type</th>
                      <th>Condition</th>
                      <th>Damage material exposed & Visible (%)</th>
                      <th>Cause of damage</th>
                      <th>Disturbance Factor</th>
                      <th>Air Movement</th>
                      <th>Damage Potential</th>
                      <th>Removed ?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading && <Spin delay={20} fullscreen size="large" />}
                    {tableData &&
                      tableData?.data?.map((item) => (
                        <tr key={item}>
                          <td>{item.ASB_TYPE ?? "--"}</td>
                          <td>{item.MATL_ID ?? "--"}</td>
                          <td style={{ width: "200px" }}>
                            {item?.COMMENTS ?? "--"}
                          </td>
                          <td style={{ width: "150px" }}>
                            {item?.MATL_LN_FT
                              ? `${item?.MATL_LN_FT}.00 LF`
                              : item?.MATL_SQ_FT
                                ? `${item?.MATL_SQ_FT}.00 SF`
                                : item?.MATL_CUB_YD
                                  ? `${item?.MATL_CUB_YD}.00 CY`
                                  : 0}
                          </td>
                          <td style={{ width: "100px" }}>--</td>
                          <td style={{ width: "150px" }}>{item?.id}</td>
                          <td>{item?.RESULTS_COUNT ?? "--"}</td>
                          {/* {
                          layerTypeOptions[item?.CATEGORY]?.find(
                            (data) =>
                              data.description == item?.materials?.MATERIAL
                            )?.description ?? "--"}{" "}
                            {item?.materials?.PERCENT_RANGE ?? "--"} */}

                          <td>
                            <td>
                              {item?.MATERIALS?.length &&
                              item?.PERCENTS?.length ? (
                                item?.MATERIALS.map((material, index) => {
                                  const percent = item?.PERCENTS[index];
                                  return (
                                    <div key={index}>
                                      {`${material}: ${parseFloat(percent).toFixed(3)}`}
                                    </div>
                                  );
                                })
                              ) : (
                                <span>No Materials</span>
                              )}
                            </td>
                          </td>

                          <td>
                            {item?.FRIABLE == 1
                              ? "YES"
                              : item?.FRIABLE == 0
                                ? "NO"
                                : "--"}{" "}
                          </td>

                          <td style={{ width: "100px" }}>
                            {item?.ACM_TYPE_CD ?? "--"}{" "}
                          </td>
                          {/* <td>{item?.MATL_COND_CD ?? '--'}</td> */}

                          <td>{item?.MATL_COND_CD ?? "--"}</td>
                          <td style={{ width: "200px" }}>
                            {item?.exposed_visible ?? "--"}{" "}
                          </td>
                          <td style={{ width: "150px" }}>
                            {item?.PHY_DAMAGE == 1
                              ? "Physical Deterioration"
                              : item?.WATER_DAMAGE == 1
                                ? "Water Deterioration"
                                : item?.DET_DAMAGE == 1
                                  ? item?.DET_DAMAGE
                                  : "--"}
                            {/* 
{item?.PHY_DAMAGE == 1 ? 'Physical' : ''}
  {item?.WATER_DAMAGE == 1 ? 'Water Deterioration' : ''}
  {item?.DET_DAMAGE == 1 ? item?.DET_DAMAGE : ''} */}
                          </td>
                          <td style={{ width: "200px" }}>
                            {item?.VISIBLE_DIST == 1
                              ? "Visible"
                              : item?.ACCESS_DIST == 1
                                ? "Access"
                                : item?.DET_DAMAGE == 1
                                  ? item?.DET_DAMAGE
                                  : ""}
                          </td>
                          <td style={{ width: "200px" }}>
                            {item?.AIR_MOVE ?? "--"}{" "}
                          </td>
                          <td style={{ width: "200px" }}>
                            {item?.DAMAGE_POTENTIAL ?? "--"}{" "}
                          </td>
                          <td style={{ width: "200px" }}>
                            {/* {item?.REMOVED ?? "--"}{" "} */}
                            {"--"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : currentTable == 2 ? (
                <table>
                  <thead>
                    <tr>
                      <th>ASB_ID</th>
                      <th>
                        <div className="d-flex align-items-center gap-1">
                          HGA <i class="fi fi-sr-sort"></i>
                        </div>
                      </th>
                      <th>SAMPLE NUMBER</th>
                      <th>MATERIAL DESCRIPTION</th>
                      <th>layer</th>
                      <th>location of sample</th>
                      <th>% asbestos</th>
                      <th>type of asbestos</th>
                      <th>found in</th>
                      <th>friable</th>
                      <th>removed?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading && <Spin delay={20} fullscreen size="large" />}
                    {tableData &&
                      tableData?.map((item, i) => (
                        <tr key={i}>
                          <td>{item?.ASB_ID}</td>
                          <td style={{ width: "250px" }}>
                            {item?.AREA_ID},{item?.COMMENTS}
                          </td>
                          <td style={{ width: "150px" }}>{item?.SAMPLE_ID}</td>
                          <td style={{ width: "100px" }}>
                            {item?.MATL_ID}
                          </td>{" "}
                          {/* MATL_DESC not in response  */}
                          <td>{item?.LAYER_ID}</td>
                          <td style={{ width: "200px" }}>
                            {item?.COMMENTS}{" "}
                            {/* SUSPECT_DSCRPTN not in response  */}
                          </td>
                          <td>{item?.PERCENT ?? "--"}</td>
                          <td style={{ width: "100px" }}>
                            {item?.MATERIAL ?? "--"}
                          </td>
                          {/* <td>{MATERIAL_TYPE[item?.CATEGORY]}</td> */}
                          <td>{item?.ASB_MATERIAL}</td>
                          <td>
                            <img
                              src={
                                Number(item?.FRIABLE == 1)
                                  ? BlueCheckIcon
                                  : RedCrossIcon
                              }
                              alt=""
                            />
                          </td>
                          <td>
                            <img
                              src={
                                item?.AREA_ABATED === "TRUE"
                                  ? BlueCheckIcon
                                  : RedCrossIcon
                              }
                              alt=""
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : currentTable == 4 ? (
                <>
                  <table>
                    <thead>
                      <tr>
                        {[
                          "SAMPLE FPLA ID",
                          "layer",
                          "HOMOGENEOUS",
                          "Morphology",
                          "color",
                          "ASBESTOS",
                          "ASBESTOS TYPE",
                          "abs%",
                          "Disp. colors",
                          "Pleochroism",
                          "BIOF",
                          "EXT. ANGLE",
                          "ELONGATION",
                          "REFR INDEX",
                          "non-asb fibers",
                        ]?.map((i) => (
                          <th key={i}>{i}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && <Spin delay={20} fullscreen size="large" />}
                      {tableData && tableData?.length > 0
                        ? tableData?.map((item, i) => (
                            <tr key={i}>
                              <td>{item?.FPLA_ID ?? "--"}</td>
                              <td>{item?.LAYER_ID ?? "--"}</td>
                              <td>
                                <img
                                  src={
                                    item?.HOMOG == "1"
                                      ? BlueCheckIcon
                                      : RedCrossIcon
                                  }
                                  alt=""
                                />
                              </td>
                              <td>{item?.MORPHOLOGY}</td>
                              <td>{item?.TEXTURE}</td>
                              <td>{MATERIAL_TYPE[item?.CATEGORY] ?? "--"}</td>
                              <td>
                                {item?.CATEGORY === "A" ? item?.MATERIAL : "--"}
                              </td>
                              <td>{item?.PERCENT_RANGE ?? "--"}</td>
                              <td>{"--"}</td>
                              <td>
                                <img
                                  src={
                                    item?.PLEOCH == "1"
                                      ? BlueCheckIcon
                                      : RedCrossIcon
                                  }
                                  alt=""
                                />
                              </td>
                              <td>{item?.BIOFRING ?? "--"}</td>
                              <td>{item?.EXT_ANGLE ?? "--"}</td>
                              <td>{item?.ELONG ?? "--"}</td>
                              <td>{item?.REF_INDEX ?? "--"}</td>
                              <td>
                                {item?.CATEGORY === "NF"
                                  ? item?.MATERIAL
                                  : "--"}
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </>
              ) : null}

              {
                // currentTable == 3 ?
                // Object.keys(tableData)?.length < 1 :
                Array.isArray(tableData) && !tableData?.length ? (
                  <div className="empty">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                ) : Object.keys(tableData)?.length < 1 ? (
                  <div className="empty">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                ) : (
                  ""
                )
              }
            </div>
          )}
          {console.log(
            tableData,
            "tableData",
            (currentTable === 1 ? tableData?.data : tableData)?.length > 0
          ) &&
            isLoading &&
            totalTableData > 10}
          {!isLoading &&
          //  Array.isArray(tableData) ?
          (currentTable === 1
            ? tableData?.data
            : currentTable === 3
              ? tableData?.groupedResult?.ASBGroupedData
              : tableData
          )?.length > 0 &&
          // :
          //  (Object.keys(tableData).length > 0)
          totalTableData > 10 ? (
            <div className="pagination">
              <Pagination
                defaultCurrent={1}
                total={totalTableData}
                current={currentPage}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default AsbestosTableThree;
