import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slice/userSlice";
import thunk from "redux-thunk";
import buildingSlice from "./slice/buildingSlice";
import surveySlice from "./slice/surveySlice";
import sampleSlice from "./slice/sampleSlice";
import resultsSlice from "./slice/resultsSlice";
import assessmentSlice from "./slice/assessmentSlice";
import attachmentSlice from "./slice/attachmentSlice";
import globalStates from "./slice/globalStates";
import entriesSlice from './slice/entriesSlice';

export const store = configureStore({
  reducer: {
    entries: entriesSlice,
    user: userSlice,
    building: buildingSlice,
    survey: surveySlice,
    sample: sampleSlice,
    result: resultsSlice,
    assessment: assessmentSlice,
    attachment: attachmentSlice,
    globalStates: globalStates,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
  //           serializableCheck: false,
  //         }),
});
