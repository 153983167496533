import React, { useEffect } from "react";
import { Pagination, Spin } from "antd";
import { fetchTableTwo, changePage } from "../../../redux/slice/entriesSlice";
import { GoBack, HeaderText, MainLayout } from "@/components";
import "./tables.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function LeadTableTwo() {
  const { rowData, isLoading, totalEntries, pageSize, currentPage } =
    useSelector((state) => state.entries);
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const buildingId = pathSegments[pathSegments.length - 1];
  const dispatch = useDispatch();
  useEffect(() => {
    if (buildingId) {
      dispatch(fetchTableTwo(buildingId, 1, pageSize));
    }
  }, [buildingId, dispatch, pageSize]);

  const handlePageChange = (page, pageSize) => {
    dispatch(changePage({ page, pageSize }));
    dispatch(fetchTableTwo(buildingId, page, pageSize));
  };
  return (
    <MainLayout>
      <div className="table-two-wrapper">
        <HeaderText>
          <GoBack />
          Table 2 · <span className="header-light-text">{buildingId}</span>
        </HeaderText>
        <div className="table-wrapper">
          {isLoading ? (
            <Spin delay={20} fullscreen size="large" />
          ) : (
            <div className="table-wrapper">
              <div className="table-outer-header">
                {/* <SearchInput
                width={300}
                placeholder="Search here..."
                className="user-search-input"
              /> */}
              </div>
              <div
                className="table-overflow-wrapper container p-0"
                id="customScrollTable"
              >
                <table>
                  <thead>
                    <tr>
                      <th>Test</th>
                      <th>Serial</th>
                      <th>Pass/Fail</th>
                      <th>Unit Type</th>
                      <th>Calibration Model / Matrix ID</th>
                      <th>Display Sigma</th>
                      <th>LOD Sigma</th>
                      <th>Pb</th>
                      <th>Pb +/-</th>
                      <th>Pb P/F</th>
                      <th>Result</th>
                      <th>Lead (mg/cm²)</th>
                      <th>Mode</th>
                      <th>Job #</th>
                      <th>Wall</th>
                      <th>Structure</th>
                      <th>Location</th>
                      <th>Member</th>
                      <th>Paint Condition</th>
                      <th>Substrate</th>
                      <th>Color</th>
                      <th>Room Name</th>
                      <th>Building Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rowData.map((data, index) => (
                      <tr key={index}>
                        <td>
                          {data.TestNumber !== "N/A" &&
                          (data.TestNumber || data.TestNumber === 0)
                            ? data.TestNumber
                            : ""}
                        </td>
                        <td>
                          {data.SerialNumber !== "N/A" &&
                          (data.SerialNumber || data.SerialNumber === 0)
                            ? data.SerialNumber
                            : ""}
                        </td>
                        <td>
                          {data.PassFail !== "N/A" &&
                          (data.PassFail || data.PassFail === 0)
                            ? data.PassFail
                            : ""}
                        </td>
                        <td>
                          {data.UnitType !== "N/A" &&
                          (data.UnitType || data.UnitType === 0)
                            ? data.UnitType
                            : ""}
                        </td>
                        <td>
                          {data.CalibrationModelMatrixID !== "N/A" &&
                          (data.CalibrationModelMatrixID ||
                            data.CalibrationModelMatrixID === 0)
                            ? data.CalibrationModelMatrixID
                            : ""}
                        </td>
                        <td>
                          {data.DisplaySigma !== "N/A" &&
                          (data.DisplaySigma || data.DisplaySigma === 0)
                            ? data.DisplaySigma
                            : ""}
                        </td>
                        <td>
                          {data.LODSigma !== "N/A" &&
                          (data.LODSigma || data.LODSigma === 0)
                            ? data.LODSigma
                            : ""}
                        </td>
                        <td>
                          {data.Pb !== "N/A" && (data.Pb || data.Pb === 0)
                            ? data.Pb
                            : ""}
                        </td>
                        <td>
                          {data.PbPlusMinus !== "N/A" &&
                          (data.PbPlusMinus || data.PbPlusMinus === 0)
                            ? data.PbPlusMinus
                            : ""}
                        </td>
                        <td>
                          {data.PbPassFail !== "N/A" &&
                          (data.PbPassFail || data.PbPassFail === 0)
                            ? data.PbPassFail
                            : ""}
                        </td>
                        <td>
                          {data.Result !== "N/A" &&
                          (data.Result || data.Result === 0)
                            ? data.Result
                            : ""}
                        </td>
                        <td>
                          {data.Lead_mg_cm2 !== "N/A" &&
                          (data.Lead_mg_cm2 || data.Lead_mg_cm2 === 0)
                            ? data.Lead_mg_cm2
                            : ""}
                        </td>
                        <td>
                          {data.Mode !== "N/A" && (data.Mode || data.Mode === 0)
                            ? data.Mode
                            : ""}
                        </td>
                        <td>
                          {data.Job_Number !== "N/A" &&
                          (data.Job_Number || data.Job_Number === 0)
                            ? data.Job_Number
                            : ""}
                        </td>
                        <td>
                          {data.Wall !== "N/A" && (data.Wall || data.Wall === 0)
                            ? data.Wall
                            : ""}
                        </td>
                        <td>
                          {data.Structure !== "N/A" &&
                          (data.Structure || data.Structure === 0)
                            ? data.Structure
                            : ""}
                        </td>
                        <td>
                          {data.Location !== "N/A" &&
                          (data.Location || data.Location === 0)
                            ? data.Location
                            : ""}
                        </td>
                        <td>
                          {data.Member !== "N/A" &&
                          (data.Member || data.Member === 0)
                            ? data.Member
                            : ""}
                        </td>
                        <td>
                          {data.Paint_Condition !== "N/A" &&
                          (data.Paint_Condition || data.Paint_Condition === 0)
                            ? data.Paint_Condition
                            : ""}
                        </td>
                        <td>
                          {data.Substrate !== "N/A" &&
                          (data.Substrate || data.Substrate === 0)
                            ? data.Substrate
                            : ""}
                        </td>
                        <td>
                          {data.Color !== "N/A" &&
                          (data.Color || data.Color === 0)
                            ? data.Color
                            : ""}
                        </td>
                        <td>
                          {data.Room_Name !== "N/A" &&
                          (data.Room_Name || data.Room_Name === 0)
                            ? data.Room_Name
                            : ""}
                        </td>
                        <td>
                          {data.Building_Type !== "N/A" &&
                          (data.Building_Type || data.Building_Type === 0)
                            ? data.Building_Type
                            : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        {!isLoading && totalEntries > 0 && totalEntries > 10 && (
          <div className="pagination">
            <Pagination
              current={currentPage}
              total={totalEntries}
              pageSize={pageSize}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
}

export default LeadTableTwo;
